import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Buffer } from "buffer";
import { useQuery } from "react-query";

import { selectEvent } from "../events/eventSlice";
import { getChurches } from "../../services/publicApi";
import { LinkListItem } from "./LinkListItem";
import { getUrl } from "../../services/helpers";
import { useParams } from "react-router-dom";

const CHURCHES_QUERY_KEY = "churches";

const ERROR_LABEL = "Tapahtui virhe.";
const LOADING_LABEL = "Ladataan...";
const NO_CHURCHES_LABEL = "Valitse tapahtuma";

const ITEM_SIZE = 72;

export function LinkList() {
  const event = useSelector(selectEvent);
  const eventId = useParams().eventId
  const churchId = useParams().churcId
  const [isFetching, setIsFetching] = useState();
  const [scrollIndex, setScrollIndex] = useState(0);
  const [links, setLinks] = useState([]);

  const { data, error, isLoading } = useQuery([CHURCHES_QUERY_KEY, event], () =>
    event && event.wt_collection === 0 ? getChurches(event.id) : []
  );

  const setLinksFromData = async () => {
    const unsortedChurches = data ? data.map(
      (church) => {
        const [id, name] = church;

        const link = `/event/${eventId}/church/${id}`;

        return { id, name, link };
      }
    ) : []
    const sortedChurches = unsortedChurches.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const specialWorkTurnLists =  event ? event.tasks.map(
      (task) => {
        const [id, name] = task;

        const link = `/event/${eventId}/wt/${id}`;

        return { id, name, link, icon: "security", onlySecurityGuards: true };

      }
    ) : []


    //ilmeisesti c : 0 antaa kaikki työvuorot
    const typeParametersBase64_3 = Buffer.from(JSON.stringify({ c : 0})).toString(
      "base64"
    );

    
    const allLinkUrl = `/event/${eventId}/wt`;
  
    const allShifts = [
      {
        link: allLinkUrl,
        name: "Kaikki työvuorot",
      }

    ];

    

    event && event.wt_collection===1?
    setLinks([...allShifts, ...specialWorkTurnLists])
    :
    setLinks([...specialWorkTurnLists, ...sortedChurches])
   
    setScrollIndex(0);
  };

useEffect(setLinksFromData, [data]);

  if (error) {
    return (
      <Box
        sx={{
          alignItems: "center",
          borderTop: 1,
          borderColor: "grey.400",
          display: "flex",
          height: ITEM_SIZE,
          justifyContent: "center",
          padding: "1rem",
        }}
      >
        <Typography>{ERROR_LABEL}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        borderTop: 1,
        borderColor: "grey.400",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      {links.length === 0 ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            height: ITEM_SIZE,
            justifyContent: "center",
            padding: "1rem",
          }}
        >
          <Typography>
            {isLoading ? LOADING_LABEL : NO_CHURCHES_LABEL}
          </Typography>
        </Box>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              itemCount={links.length}
              itemData={links}
              itemSize={ITEM_SIZE}
              initialScrollOffset={scrollIndex * ITEM_SIZE}
              onItemsRendered={({ visibleStartIndex }) => {
                setScrollIndex(visibleStartIndex);
              }}
              width={width}
            >
              {({ index, style, data }) => {
                return (
                  <LinkListItem
                    index={index}
                    style={style}
                    data={data}
                    height={ITEM_SIZE}
                  />
                );
              }}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </Box>
  );
}
