import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import TimeIcon from "@mui/icons-material/AccessTime";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/private-theming";
import { setSelectedWorkTurn } from "./selectedWorkTurnSlice";
import QRCode from "qrcode.react";
import {getSelfRegistrationUrlForWorkTurnQr } from "../../services/helpers";

const COMMITTEE_TEXT = "toimikunta";
const DAY_FORMAT = "dd D.M.";
const TIME_FORMAT = "H.mm";
const HOUR_FORMAT = "H";
const DATE_FORMAT = `${DAY_FORMAT} [klo] ${TIME_FORMAT}`;


const SHIFTS = {
  morning: { text: "aamu", alpha: 0.75 },
  day: { text: "päivä", alpha: 0.5 },
  evening: { text: "ilta", alpha: 0.75 },
  night: { text: "yö", alpha: 1.0 }
};

export function WorkTurn({ data = [], height, index, style }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const DIVIDER_COLOR = grey[200];
  const GREY_COLOR = theme.palette.grey[600];
  const THEME_COLOR = theme.palette.primary.main;
  const WHITE_COLOR = "white";

  const workTurn = data[index];
  const { age_min, age_recommended, committee, end_time, start_time, task } =
    workTurn;
  const id = workTurn.id
  const start = moment(start_time);
  const end = moment(end_time);
  const duration = moment.duration(end.diff(start)).asHours();
  const startTime = start.format(DATE_FORMAT);
  const endTime = end.format(isSameDay(start, end) ? TIME_FORMAT : DATE_FORMAT);
  const shiftTime = start
    .add(end.diff(start, "hours") / 2, "hours")
    .format(HOUR_FORMAT);

  const timeText = `${startTime} — ${endTime}`;
  const committeeText = committee.replace(COMMITTEE_TEXT, "");
  const durationText = `${duration.toFixed(hasDecimals(duration) ? 1 : 0)}h`;
  const { text: shiftText, alpha: shiftAlpha } = getShift(shiftTime);

  const age = age_min || age_recommended;
  const ageText = age ? `${age}+` : "—";

  return (
    <Stack
      onClick={() => dispatch(setSelectedWorkTurn(workTurn))}
      style={style}
    >
      <Stack direction="row" sx={{ height: height - 1 }}>
        <Typography
          sx={{
            alignItems: "center",
            bgcolor: THEME_COLOR,
            color: WHITE_COLOR,
            display: "flex",
            fontSize: committeeText.length > 9 ? ".375rem" : ".5rem",
            justifyContent: "center",
            marginLeft: "-0.1rem",
            minWidth: "1.5rem",
            textOrientation: "upright",
            textTransform: "uppercase",
            width: "1rem",
            writingMode: "vertical-rl"
          }}
        >
          {committeeText}
        </Typography>
        <Divider color={DIVIDER_COLOR} flexItem orientation="vertical" />
        <Stack sx={{ flexGrow: 1, padding: ".75rem 1rem" }}>
          <Typography
            sx={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              hyphens: "auto",
              lineHeight: "normal",
              "overflow-wrap": "break-word",
              "word-break": "break-word",
              "word-wrap": "break-word"
            }}
          >
            {task}
          </Typography>
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              flexGrow: 1,
              padding: ".25rem .5rem 0 0"
            }}
          >
            <TimeIcon sx={{ color: GREY_COLOR, fontSize: ".9375rem" }} />
            <Typography
              sx={{
                color: GREY_COLOR,
                fontSize: ".9375rem",
                lineHeight: "normal",
                paddingLeft: ".5rem"
              }}
            >
              {timeText}
            </Typography>

          </Stack>
        </Stack>
        {/* <QRCode value={getSelfRegistrationUrlForWorkTurnQr({ id, task })} /> */}
        <Divider color={DIVIDER_COLOR} flexItem orientation="vertical" />
        <Stack
          sx={{
            bgcolor: alpha(THEME_COLOR, shiftAlpha),
            color: WHITE_COLOR,
            minWidth: "3.125rem",
            width: "3.125rem"
          }}
        >
          <Typography
            sx={{
              alignItems: "center",
              display: "flex",
              fontSize: ".875rem",
              flexGrow: 1,
              justifyContent: "center",
              textTransform: "capitalize"
            }}
          >
            {shiftText}
          </Typography>
          <Typography
            sx={{
              alignItems: "center",
              display: "flex",
              fontSize: ".9375rem",
              flexGrow: 1,
              justifyContent: "center"
            }}
          >
            {durationText}
          </Typography>
          <Typography
            sx={{
              alignItems: "center",
              display: "flex",
              fontSize: ".875rem",
              flexGrow: 1,
              justifyContent: "center"
            }}
          >
            {ageText}
          </Typography>
        </Stack>
      </Stack>
      <Divider color={DIVIDER_COLOR} />
    </Stack>
  );
}

const hasDecimals = number => {
  return number % 1 !== 0;
};

const isSameDay = (a, b) => {
  return a.format(DAY_FORMAT) === b.format(DAY_FORMAT);
};

const getShift = hour => {
  return hour >= 6 && hour < 12
    ? SHIFTS.morning
    : hour >= 12 && hour < 18
    ? SHIFTS.day
    : hour >= 18 && hour <= 23
    ? SHIFTS.evening
    : SHIFTS.night;
};
