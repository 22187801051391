import React from "react";
import { useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { selectChurch } from "./churchSlice";

export function ChurchBar() {
  const church = useSelector(selectChurch);
  const isChurchAvailable = church !== null && church.name;

  return isChurchAvailable ? (
    <Stack
      alignItems={"center"}
      direction={"row"}
      sx={{ color: "rgba(0, 0, 0, .6)", padding: "0 1rem 1rem 1rem" }}
    >
      <HomeIcon sx={{ fontSize: "1.5rem", paddingBottom: "2px" }} />
      <Typography sx={{ fontSize: "1rem", paddingLeft: ".5rem" }}>
        {church.name}
      </Typography>
    </Stack>
  ) : null;
}
