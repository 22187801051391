import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0
};

export const totalCountSlice = createSlice({
  name: "totalCount",
  initialState,
  reducers: {
    setTotalCount: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setTotalCount } = totalCountSlice.actions;

export const selectTotalCount = state => state.totalCount.value;

export default totalCountSlice.reducer;
