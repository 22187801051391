import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import TimeIcon from "@mui/icons-material/AccessTime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/private-theming";
import SecurityIcon from '@mui/icons-material/Security';

import { useNavigate } from "react-router-dom";

const SELECT_CHURCH = "VALITSE";
const COMMITTEE_TEXT = "toimikunta";
const DAY_FORMAT = "dd D.M.";
const TIME_FORMAT = "H.mm";
const HOUR_FORMAT = "H";
const DATE_FORMAT = `${DAY_FORMAT} [klo] ${TIME_FORMAT}`;

const SHIFTS = {
  morning: { text: "aamu", alpha: 0.75 },
  day: { text: "päivä", alpha: 0.5 },
  evening: { text: "ilta", alpha: 0.75 },
  night: { text: "yö", alpha: 1.0 },
};

export function LinkListItem({ data = [], height, index, style }) {
  const theme = useTheme();

  const navigate = useNavigate()

  const DIVIDER_COLOR = grey[200];
  const GREY_COLOR = theme.palette.grey[600];
  const THEME_COLOR = theme.palette.primary.main;
  const WHITE_COLOR = "white";

  const { id, name, icon, link } = data[index];

  const handleSelectClick = () => {
    navigate(link);
  };

  return (
    <Stack style={style} onClick={handleSelectClick}>
      <Stack alignItems="center" direction="row" sx={{ height: height - 1, padding:  "0 .5rem 0rem 1rem"  }}>
         
        {icon === "security" && 
        <Box sx={{ color: GREY_COLOR, padding: "0.4rem 0.5rem 0rem 0" }}><SecurityIcon/></Box> }
        <Typography
          sx={{
            
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            lineHeight: "normal",
          }}
        >
          {name}
        </Typography>

        <Divider />
        <Box sx={{ flex: "0 1 auto", padding: "1rem" }}>
          <Button fullWidth onClick={handleSelectClick} variant="contained">
            {SELECT_CHURCH}
          </Button>
        </Box>
      </Stack>

      <Divider color={DIVIDER_COLOR} />
    </Stack>
  );
}
