import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NoEvents } from "./NoEvents";
import { setEvent, selectEvent } from "./eventSlice";
import { selectChurch } from "../church/churchSlice";
import { getEvents, getEventsByChurch } from "../../services/publicApi";
import { useNavigate, useParams } from "react-router-dom";


const ERROR_LABEL = "Tapahtui virhe.";
const EVENT_LABEL = "Tapahtuma";

const EVENTS_QUERY_KEY = "events";
const EVENTSBYCHURCH_QUERY_KEY = "eventsById"

export function EventSelector() {
  const event = useSelector(selectEvent);
  const eventId = useParams().eventId
  const dispatch = useDispatch();
  const church = useSelector(selectChurch);
  const churchId = useParams().churchId
  const navigate = useNavigate();

  
  const [finalData, setFinalData] = useState()

  const { data, error, isLoading } = useQuery(
    [EVENTS_QUERY_KEY],
    () => getEvents(),
    { 
      refetchOnWindowFocus: false, // prevent unnecessary refetches
      staleTime: Infinity // set to infinite so data is never considered stale
    }
  );

  const { data: dataBychurch } = useQuery(
    [EVENTSBYCHURCH_QUERY_KEY],
    () => churchId? getEventsByChurch(churchId) : [],
    {
      enabled: Boolean(churchId),
    }
  );

  
  
  
  

  useEffect(() => {
    if(churchId && dataBychurch && data){
      //console.log(dataBychurch)
      setFinalData(data.filter( event => dataBychurch.event_list.includes(event.id)))
      
      // const selectedEvent = finalData.find(event => event.id === eventId);
      // dispatch(selectEvent(selectedEvent))

      
    }
    else{
      setFinalData(data)
      
    }
  },[churchId, dataBychurch, data])

  useEffect(() => {
    if (finalData && eventId) {
      console.log("eventId ennen dispatchia", eventId)
      finalData.map(event => console.log("mapin event",event.id))
      const selectedEvent = finalData.find(event => event.id == eventId);
      console.log("final data ennen dispatchia", finalData)
      console.log("selected event ennen dispatchia", selectedEvent)
      dispatch(setEvent(selectedEvent))
    }
  }, [finalData, eventId, dispatch]);
  
  // useEffect(() => {
  //   const selectedEvent = data.find(event => event.id === eventId);
  //   setEvent(selectedEvent)
  // }, [eventId])


  if (error) {
    return (
      <Box sx={{ alignSelf: "center", padding: "0 .5rem 1rem .5rem" }}>
        {ERROR_LABEL}
      </Box>
    );
  }

  const handleSelectionChange = inputEvent => {
    const selectedEventId = parseInt(inputEvent.target.value);
    const selectedEvent = finalData.find(event => event.id === selectedEventId);
    navigate(`/event/${selectedEventId}`)
    dispatch(setEvent(selectedEvent))
  };

  if (!isLoading && !data) {
    return <NoEvents />;
  }
  

  return (
    <Box sx={{ flexGrow: 1, padding: "0 .5rem 1rem .5rem" }}>
      <FormControl fullWidth>
        <InputLabel id="event-select-label">{EVENT_LABEL}</InputLabel>
        {console.log("final data kompo", finalData)}
        <Select
          labelId={"event-select-label"}
          label={EVENT_LABEL}
          onChange={handleSelectionChange}
          value={event && event.id !== 0 ? event.id : ""}
        >
          {finalData &&
            finalData.map(event => (
              <MenuItem key={event.id} value={event.id}>
                {event.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
