import React from "react";
import Box from "@mui/material/Box";

const NO_COMMITTEES_LABEL = "Ei toimikuntia.";

export function NoCommittees() {
  return (
    <Box sx={{ alignSelf: "center", padding: "0 .5rem 1rem .5rem" }}>
      {NO_COMMITTEES_LABEL}
    </Box>
  );
}
