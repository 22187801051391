import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEvent: (state, action) => {
      state.value = action.payload;
    },
    clearEvent: (state, action) => {
      return null;
    },
  }
});

export const { setEvent, clearEvent } = eventSlice.actions;

export const selectEvent = state => state.event.value;

export default eventSlice.reducer;
