import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: ""
};

export const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setDate } = dateSlice.actions;

export const selectDate = state => state.date.value;

export default dateSlice.reducer;
