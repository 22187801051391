import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    value: ""
}



export const committeeSlice = createSlice({
  name: "committee",
  initialState,
  reducers: {
    setCommittee: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setCommittee } = committeeSlice.actions;

export const selectCommittee = state => state.committee.value

export default committeeSlice.reducer;
