import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Loading } from "../loading/Loading";
import { WorkTurn } from "../workTurn/WorkTurn";
import { setTotalCount } from "./totalCountSlice";
import { selectDate } from "../dates/dateSlice";
import { selectSearchText } from "../search/searchSlice";
import { getWorkTurns } from "../../services/publicApi";
import { useParams } from "react-router-dom";
import { selectCommittee } from "../committee/committeeSlice";

const ERROR_LABEL = "Tapahtui virhe.";
const LOADING_LABEL = "Ladataan...";
const NO_WORK_TURNS_LABEL = "Ei työvuoroja.";

const ITEM_SIZE = 150;
const PAGE_SIZE = 25;
const WORK_TURN_QUERY_KEY = "workTurns";

export function WorkTurnList() {
  const workTurnType = useParams().typeId
  const churchId = useParams().churchId
  const date = useSelector(selectDate);
  const eventId = useParams().eventId
  const committee = useSelector(selectCommittee)
  const query = useSelector(selectSearchText);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [scrollIndex, setScrollIndex] = useState(0);

  const { data, error, isFetching, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      [WORK_TURN_QUERY_KEY, eventId, query, date, committee],
      ({ queryKey: [, eventId, query, date, committee], pageParam = 0 }) =>
        eventId
          ? getWorkTurns({
              eventId,
              churchId,
              offset: pageParam * PAGE_SIZE,
              limit: PAGE_SIZE,
              q: query,
              date,
              workTurnType,
              committee
            })
          : [],
      { getNextPageParam: (lastPage, pages) => lastPage.nextId }
    );

  useEffect(() => {
    setScrollIndex(0);
    queryClient.invalidateQueries(WORK_TURN_QUERY_KEY);
  }, [queryClient, query, date]);

  useEffect(() => {
    if (data && data.pages && data.pages.length > 0) {
      const { totalCount } = data.pages[0];
      dispatch(setTotalCount(totalCount));
    }
  }, [dispatch, data]);

  if (error) {
    return (
      <Box
        sx={{
          alignItems: "center",
          borderTop: 1,
          borderColor: "grey.400",
          display: "flex",
          height: ITEM_SIZE,
          justifyContent: "center",
          padding: "1rem"
        }}
      >
        <Typography>{ERROR_LABEL}</Typography>
      </Box>
    );
  }

  const workTurns =
    data && data.pages
      ? data.pages
          .map(page => page.workTurns)
          .filter(p => p)
          .flat()
      : [];

  const itemCount = hasNextPage ? workTurns.length + 1 : workTurns.length;
  const isItemLoaded = index => !hasNextPage || index < workTurns.length;
  const loadMoreItems = isFetching
    ? () => {}
    : (offset, limit) => fetchNextPage();

  return (
    <Box
      sx={{
        borderTop: 1,
        borderColor: "grey.400",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%"
      }}
    >
      {workTurns.length === 0 ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            height: ITEM_SIZE,
            justifyContent: "center",
            padding: "1rem"
          }}
        >
          <Typography>
            {isFetching ? LOADING_LABEL : NO_WORK_TURNS_LABEL}
          </Typography>
        </Box>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  height={height}
                  itemCount={itemCount}
                  itemData={workTurns}
                  itemSize={ITEM_SIZE}
                  initialScrollOffset={scrollIndex * ITEM_SIZE}
                  onItemsRendered={({
                    overscanStartIndex,
                    overscanStopIndex,
                    visibleStartIndex,
                    visibleStopIndex
                  }) => {
                    setScrollIndex(visibleStartIndex);
                    onItemsRendered({
                      overscanStartIndex,
                      overscanStopIndex,
                      visibleStartIndex,
                      visibleStopIndex
                    });
                  }}
                  ref={ref}
                  width={width}
                >
                  {({ index, style, data }) =>
                    isItemLoaded(index) ? (
                      <WorkTurn
                        index={index}
                        style={style}
                        data={data}
                        height={ITEM_SIZE}
                      />
                    ) : (
                      <Loading height={ITEM_SIZE} style={style} />
                    )
                  }
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </Box>
  );
}
