import { createSlice } from "@reduxjs/toolkit";
import queryString from "query-string";

const queryParameters = queryString.parse(window.location.search);

let initialValue = null

try { 
    const initialValueCandidate = queryParameters.c 
    ? JSON.parse(Buffer.from(queryParameters.c, "base64").toString("utf-8"))
    : undefined

    if(initialValueCandidate && initialValueCandidate.id && initialValueCandidate.name) {
        initialValue = initialValueCandidate
    }
} catch(error) {
  // fail quietly
  console.info(error)
}

export const churchSlice = createSlice({
  name: "church",
  initialState: {
      value: initialValue
  },
  reducers: {
    setChurch: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setChurch } = churchSlice.actions;

export const selectChurch = state => {
    return state.church.value;
}

export default churchSlice.reducer;
