import React from "react";
import Box from "@mui/material/Box";

const NO_EVENTS_LABEL = "Ei tapahtumia.";

export function NoEvents() {
  return (
    <Box sx={{ alignSelf: "center", padding: "0 .5rem 1rem .5rem" }}>
      {NO_EVENTS_LABEL}
    </Box>
  );
}
