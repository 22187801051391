import moment from "moment";
import { getUrl } from "./helpers";

moment.locale("fi");

const VALID_DATES = ["ma", "ti", "ke", "to", "pe", "la", "su"];
const DATE_FORMAT = "dd";

const {
  REACT_APP_EVENTS_PATH,
  REACT_APP_CHURCHES_PATH,
  REACT_APP_WORK_TURNS_PATH,
  REACT_APP_TOTAL_COUNT_HEADER
} = process.env;

export const getEvents = async () => {
  try {
    const response = await fetch(getUrl(REACT_APP_EVENTS_PATH));
    const result = await response.json();
    return result
  } catch (e) {
    return null;
  } 
};


export const getEventsByChurch = async (churchId) =>{
  try {
    const response = await fetch(getUrl(`${REACT_APP_CHURCHES_PATH}?c=${churchId}`));
    const result = await response.json();
    return result;
  } catch (e) {
    return null;
  }
}

export const getChurches = async (eventId) => {
  try {    
    const response = await fetch(getUrl(`${REACT_APP_CHURCHES_PATH}/${eventId}`));
    const result = await response.json();

    return result;
  } catch (e) {
    return null;
  }
};

export const getWorkTurns = async ({
  eventId,
  churchId,
  offset,
  limit,
  q,
  fields,
  date,
  workTurnType,
  committee
}) => {
  const isValidDate =
    date && VALID_DATES.includes(moment(date).format(DATE_FORMAT));

  try {
    const response = await fetch(
      getUrl(`${REACT_APP_WORK_TURNS_PATH}/${eventId}`, {
        o: offset,
        l: limit,
        s: q,
        f: fields,
        c: churchId ? churchId : undefined,
        j: workTurnType,
        ...(isValidDate && { d: moment(date).format(DATE_FORMAT) }),
        cm: committee
      })
    );
    const workTurns = await response.json();

    const totalCount = parseInt(
      response.headers.get(REACT_APP_TOTAL_COUNT_HEADER)
    );

    const rowLimit = limit || totalCount;
    const currPage = offset / rowLimit;
    const nextPage = currPage + 1;
    const lastPage = Math.ceil(totalCount / rowLimit);
    const nextId = nextPage < lastPage ? nextPage : undefined;

    return {
      workTurns,
      totalCount,
      nextId
    };
  } catch (e) {
    return null;
  }
};
