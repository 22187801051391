import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fi";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {selectCommittee, setCommittee} from "./committeeSlice"
import {selectEvent} from "../events/eventSlice";
import {NoCommittees} from "./NoCommittees";


moment.locale("fi");

const ALL_POSSIBLE_DATES_LABEL = "Kaikki";
const DATE_LABEL = "Toimikunta";
const EMPTY_COMMITTEE_VALUE = "";

export function CommitteeSelector() {
  const committee = useSelector(selectCommittee);
  const dispatch = useDispatch();
  const event = useSelector(selectEvent);

  const comm_names = event ? event.committees: [];

  if (comm_names.length === 0 /*Loading*/ ) {
    return <NoCommittees />;
  }

  const handleSelectionChange = event => {
    console.log(event.target.value)
    dispatch(setCommittee(event.target.value));
  };
  
  return (
    <Box sx={{ minWidth: 120, padding: "0 .5rem 1rem .5rem", flexGrow: 1 }}>
      <FormControl fullWidth>
        <InputLabel id="date-select-label">{DATE_LABEL}</InputLabel>
        <Select
          labelId={"date-select-label"}
          value={committee ? committee : EMPTY_COMMITTEE_VALUE}
          onChange={handleSelectionChange}
          label={DATE_LABEL}
        >
          <MenuItem key={ALL_POSSIBLE_DATES_LABEL} value={EMPTY_COMMITTEE_VALUE}>
            {ALL_POSSIBLE_DATES_LABEL}
          </MenuItem>
          {comm_names.map(committee => (
            <MenuItem key={committee} value={committee}>
              {committee}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
