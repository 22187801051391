import React from "react";
import Box from "@mui/material/Box";

const NO_POSSIBLE_DATES_LABEL = "Ei päiviä.";

export function NoPossibleDates() {
  return (
    <Box sx={{ alignSelf: "center", padding: "0 .5rem 1rem .5rem" }}>
      {NO_POSSIBLE_DATES_LABEL}
    </Box>
  );
}
