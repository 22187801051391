import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ApplicationBar } from "../features/appbar/ApplicationBar";
import { ChurchBar } from "../features/church/ChurchBar";
import { Toolbar } from "../features/toolbar/Toolbar";
import { WorkTurnList } from "../features/workTurns/WorkTurnList";
import { Details } from "../features/details/Details";

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#025D8F"
    }
  }
});

function WorkTurnListPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh"
          }}
        >
          <ApplicationBar />
          <ChurchBar />
          <Toolbar />
          <WorkTurnList />
          <Details />
        </Box>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default WorkTurnListPage;
