import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import churchReducer from "../features/church/churchSlice";
import dateReducer from "../features/dates/dateSlice";
import eventReducer from "../features/events/eventSlice";
import searchReducer from "../features/search/searchSlice";
import totalCountReducer from "../features/workTurns/totalCountSlice";
import selectedWorkTurnReducer from "../features/workTurn/selectedWorkTurnSlice";
import workTurnTypeReducer from "../features/workTurnType/workTurnTypeSlice";
import committeeReducer from "../features/committee/committeeSlice";

export const store = configureStore({
  reducer: {
    church: churchReducer,
    date: dateReducer,
    event: eventReducer,
    search: searchReducer,
    totalCount: totalCountReducer,
    selectedWorkTurn: selectedWorkTurnReducer,
    workTurnType: workTurnTypeReducer,
    committee: committeeReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware()
});

setupListeners(store.dispatch);
