import React from "react";
import Box from "@mui/material/Box";
import { DateSelector } from "../dates/DateSelector";
import { EventSelector } from "../events/EventSelector";
import { CommitteeSelector } from "../committee/CommitteeSelector"

export function Toolbar({hideDaySelector}) {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", padding: "0 .5rem" }}>
      <EventSelector />
      {!hideDaySelector ? <DateSelector /> : null}
      {!hideDaySelector ? <CommitteeSelector /> : null}
    </Box>
  );
}
