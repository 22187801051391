import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";

const DIVIDER_COLOR = grey[200];
const LOADING_LABEL = "Ladataan...";

export function Loading({ height, style }) {
  return (
    <Box style={style}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: height - 1,
          justifyContent: "center"
        }}
      >
        <Typography sx={{ alignItems: "center", padding: "0 1rem" }}>
          {LOADING_LABEL}
        </Typography>
      </Box>
      <Divider color={DIVIDER_COLOR} />
    </Box>
  );
}
