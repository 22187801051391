import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import StartIcon from "@mui/icons-material/AccessTime";
import CommitteeIcon from "@mui/icons-material/AssignmentOutlined";
import EndIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DurationIcon from "@mui/icons-material/Timelapse";
import PersonIcon from "@mui/icons-material/Person";
import TagIcon from "@mui/icons-material/Tag";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/private-theming";
import {
  setSelectedWorkTurn,
  selectSelectedWorkTurn,
} from "../workTurn/selectedWorkTurnSlice";
import { getSelfRegistrationUrlForWorkTurn } from "../../services/helpers";

const DATE_FORMAT = "dd D.M. [klo] H.mm";
const GREY_COLOR = "grey.600";
const REQUEST_BUTTON_LABEL = "Varaa työvuoro";
const REQUIREMENTS_LABEL = "Erityisvaatimukset:";

export function Details() {
  const theme = useTheme();
  const selectedWorkTurn = useSelector(selectSelectedWorkTurn);
  const dispatch = useDispatch();

  const iPhone678X = useMediaQuery("(min-width:375px)");
  const iPhone678Plus = useMediaQuery("(min-width:414px)");
  const iPad = useMediaQuery("(min-width:768px)");

  const drawerWidth = iPad ? 384 : iPhone678Plus ? 344 : iPhone678X ? 305 : 250;

  const PRIMARY_THEME_COLOR = theme.palette.primary.main;

  const {
    age_min,
    age_recommended,
    committee,
    description,
    id,
    requirements,
    task,
    start_time: start,
    end_time: end,
  } = selectedWorkTurn || {};

  const startMoment = moment(start);
  const endMoment = moment(end);
  const startTime = startMoment.format(DATE_FORMAT);
  const endTime = endMoment.format(DATE_FORMAT);
  const duration = moment.duration(endMoment.diff(startMoment)).asHours();
  const durationText = `${duration.toFixed(duration % 1 !== 0 ? 1 : 0)}h`;

  const handleRequestButtonClick = () => {
    window.location.href = getSelfRegistrationUrlForWorkTurn({ id, task });
  };

  const handleCloseClick = () => {
    dispatch(setSelectedWorkTurn(null));
  };

  return (
    <Drawer
      anchor={"right"}
      onClose={handleCloseClick}
      open={selectedWorkTurn !== null}
    >
      <Stack sx={{ height: "100%", width: drawerWidth }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ bgcolor: PRIMARY_THEME_COLOR, padding: ".5rem" }}
        >
          <Stack
            alignItems="center"
            direction="row"
            sx={{ color: "white", paddingLeft: ".5rem" }}
          >
            <TagIcon sx={{ fontSize: "1.5rem" }} />
            <Typography sx={{ paddingLeft: ".5rem" }}>{id}</Typography>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            sx={{ color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography
          variant="subtitle1"
          sx={{
            hyphens: "auto",
            "overflow-wrap": "break-word",
            padding: ".75rem 1.5rem",
            "word-break": "break-word",
            "word-wrap": "break-word"
          }}
        >
          {task}
        </Typography>
        <Divider />
        <Box sx={{ flex: "1 1 auto", overflow: "scroll" }}>
          <Stack sx={{ color: GREY_COLOR, padding: ".5rem 1rem" }}>
            <Stack
              alignItems="center"
              direction="row"
              sx={{ padding: ".25rem .5rem" }}
            >
              <CommitteeIcon sx={{ fontSize: "1.5rem" }} />
              <Typography sx={{ paddingLeft: "1rem" }}>{committee}</Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              sx={{ padding: ".25rem .5rem" }}
            >
              <StartIcon sx={{ fontSize: "1.5rem" }} />
              <Typography sx={{ paddingLeft: "1rem" }}>{startTime}</Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              sx={{ padding: ".25rem .5rem" }}
            >
              <EndIcon sx={{ fontSize: "1.5rem" }} />
              <Typography sx={{ paddingLeft: "1rem" }}>{endTime}</Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              sx={{ padding: ".25rem .5rem" }}
            >
              <DurationIcon sx={{ fontSize: "1.5rem" }} />
              <Typography sx={{ paddingLeft: "1rem" }}>
                {durationText}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              sx={{ padding: ".25rem .5rem" }}
            >
              <PersonIcon sx={{ fontSize: "1.5rem" }} />
              <Typography sx={{ paddingLeft: "1rem" }}>
                {age_min ? `${age_min}+` : "—"}
              </Typography>
              {age_recommended && age_recommended !== age_min && (
                <Typography sx={{ paddingLeft: ".5rem" }}>
                  {age_recommended ? `(suositus ${age_recommended}+)` : "—"}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Divider />
          <Typography variant="body1" sx={{ padding: ".75rem 1.5rem" }}>
            {description}
          </Typography>
          <Divider />
          {requirements && requirements.length > 0 && (
            <>
              <Typography sx={{ padding: ".75rem 1.5rem" }}>
                {REQUIREMENTS_LABEL}
              </Typography>
              <Stack sx={{ padding: "0 1.5rem" }}>
                {requirements
                  .map((req) => {
                    // fix a wrong term that is conventionally used throughout suvivuorot.fi/SRK
                    // also, the encoding AND content-type is wrong in @suvivuorot.fi api, taking it easy and only
                    // relying on the non-umlaut ending of "Järjestysmies kortti"
                    return {
                      ...req,
                      name: req.name.match("rjestysmies kortti")
                        ? "Järjestyksenvalvojakortti"
                        : req.name,
                    };
                  })
                  .map((req) => (
                    <Stack key={req.name} sx={{ paddingBottom: "1rem" }}>
                      <Typography
                        variant="body2"
                        sx={{ paddingBottom: ".5rem" }}
                      >
                        {req.name}
                      </Typography>
                      <Typography color={GREY_COLOR} variant="body2">
                        {req.description}
                      </Typography>
                    </Stack>
                  ))}
              </Stack>
            </>
          )}
        </Box>
        <Divider />
        <Box sx={{ flex: "0 1 auto", padding: "1rem" }}>
          <Button
            fullWidth
            onClick={handleRequestButtonClick}
            variant="contained"
          >
            {REQUEST_BUTTON_LABEL}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
}
