import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null
};

export const selectedWorkTurnSlice = createSlice({
  name: "selectedWorkTurn",
  initialState,
  reducers: {
    setSelectedWorkTurn: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setSelectedWorkTurn } = selectedWorkTurnSlice.actions;

export const selectSelectedWorkTurn = state => state.selectedWorkTurn.value;

export default selectedWorkTurnSlice.reducer;
