import { createSlice } from "@reduxjs/toolkit";
import queryString from "query-string";

const queryParameters = queryString.parse(window.location.search);

let initialValue = null;

try {
  initialValue = queryParameters.t
    ? JSON.parse(Buffer.from(queryParameters.t, "base64").toString("utf-8"))
    : undefined;
} catch (error) {
  // fail quietly
  console.info(error);
}

export const workTurnTypeSlice = createSlice({
  name: "workTurnType",
  initialState: {
    value: initialValue,
  },
  reducers: {
    setWorkTurnType: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setWorkTurnType } = workTurnTypeSlice.actions;

export const selectWorkTurnType = (state) => {
  return state.workTurnType.value;
};

export default workTurnTypeSlice.reducer;
