import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Badge from "@mui/material/Badge";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import { setSearchText } from "./searchSlice";
import { selectTotalCount } from "../workTurns/totalCountSlice";
import { debounce } from "../helpers";

const DEBOUNCE_TIME = 300;
const PLACEHOLDER_TEXT = "Hae";

const TotalCountBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    border: `2px solid ${theme.palette.background.paper}`,
    transform: "scale(1) translate(10px, -5px)"
  }
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

export function SearchBar() {
  const totalCount = useSelector(selectTotalCount);
  const dispatch = useDispatch();

  const debounceSetText = useMemo(
    () => debounce(value => dispatch(setSearchText(value)), DEBOUNCE_TIME),
    [dispatch]
  );

  const handleInputChange = event => {
    const text = event.target.value;
    debounceSetText(text);
  };

  return (
    <Search>
      <TotalCountBadge badgeContent={totalCount} color="primary" max={99999}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={PLACEHOLDER_TEXT}
          inputProps={{ "aria-label": "search" }}
          onChange={handleInputChange}
        />
      </TotalCountBadge>
    </Search>
  );
}
