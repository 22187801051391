import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { SearchBar } from "../search/SearchBar";
import { getUrl } from "../../services/helpers"
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearEvent} from "../events/eventSlice";
import { Switch } from "@mui/material";

const HEADER_TEXT = "Suvivuorot";

export function ApplicationBar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const handleHomeClick = () => {
    dispatch(clearEvent)
    navigate('/')
  }
  return (
    <Box sx={{ paddingBottom: "1rem" }}>
      <AppBar position="static">
        <Toolbar>
          <Typography
            onClick={handleHomeClick}
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, minWidth: "10rem", cursor: "pointer"}}

          >
            {HEADER_TEXT}
          </Typography>
          <HomeIcon onClick={handleHomeClick} sx={{minWidth: "2rem", padding: "0 0.5rem 0 0"}}/>
          <SearchBar />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
