import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fi";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NoPossibleDates } from "./NoPossibleDates";
import { setDate } from "./dateSlice";
import { selectDate } from "../dates/dateSlice";
import { selectEvent } from "../events/eventSlice";

moment.locale("fi");

const ALL_POSSIBLE_DATES_LABEL = "Kaikki";
const DATE_LABEL = "Päivä";
const DATE_STATE_FORMAT = "YYYY-MM-DD";
const DATE_MENU_ITEM_FORMAT = "dd D. MMMM";
const EMPTY_DATE_VALUE = " ";
const WORK_TURNS_START_DATE_FIELD = "work_turns_start_date";
const DAYS_FIELD = "days";

export function DateSelector() {
  const date = useSelector(selectDate);
  const event = useSelector(selectEvent);
  const dispatch = useDispatch();

  if (!event) {
    return <NoPossibleDates />;
  }

  const possibleDates = [0, 1, 2, 3, 4, 5, 6]
    .map(day => moment(event[WORK_TURNS_START_DATE_FIELD]).add(day, "days"))
    .filter(date => event[DAYS_FIELD].includes(date.format("dd")))
    .map(date => date.format(DATE_STATE_FORMAT));

  if (possibleDates.length === 0) {
    return <NoPossibleDates />;
  }

  const handleSelectionChange = event => {
    dispatch(setDate(event.target.value));
  };

  return (
    <Box sx={{ minWidth: 120, padding: "0 .5rem 1rem .5rem", flexGrow: 1 }}>
      <FormControl fullWidth>
        <InputLabel id="date-select-label">{DATE_LABEL}</InputLabel>
        <Select
          labelId={"date-select-label"}
          value={date ? date : EMPTY_DATE_VALUE}
          onChange={handleSelectionChange}
          label={DATE_LABEL}
        >
          <MenuItem key={ALL_POSSIBLE_DATES_LABEL} value={EMPTY_DATE_VALUE}>
            {ALL_POSSIBLE_DATES_LABEL}
          </MenuItem>
          {possibleDates.map(date => (
            <MenuItem key={date} value={date}>
              {moment(date).format(DATE_MENU_ITEM_FORMAT)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
